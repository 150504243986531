/*eslint-disable no-unused-vars*/

import tippy from 'tippy.js'
import './assets/js/css/ImportCss'
import { Fancybox } from '@fancyapps/ui'
import SwiperSlider from './assets/js/Swiper/Swiper'
import FaqItem from '@/assets/js/Faq/FaqItem'
import ShowHidden from '@/assets/js/ShowHidden/ShowHidden'
import HeaderMobile from '@/assets/js/Header/HeaderMobile/HeaderMobile'
import MenuMobile from '@/assets/js/Menu/MenuMobile/MenuMobile'
import MenuProduct from '@/assets/js/Menu/MenuProduct/MenuProduct'
import HeaderFixed from '@/assets/js/HeaderFixed/HeaderFixed'
import ButtonTop from '@/assets/js/ButtonTop/ButtonTop'
import { createApp } from 'vue'
import form from '@/store/form'
import Maska from 'maska'
import AppFormBottom from '@/components/Form/FormBottom/AppFormBottom'
import AppFormSale from '@/components/Form/FormSale/AppFormSale.vue'
import AppFormPopupConsult from '@/components/Form/Popup/FormPopupConsult/AppFormPopupConsult'
import AppFormPopupSend from '@/components/Form/Popup/FormPopupSend/AppFormPopupSend'
import AppFormPopupHeader from '@/components/Form/Popup/FormPopupHeader/AppFormPopupHeader'

// Подключаем глобально jQuery
const $ = window.$ = window.jQuery = require('jquery')

document.addEventListener('DOMContentLoaded', () => {
    Fancybox.bind('[data-subscribe]')

    tippy('.tippy--icon', {
        theme: 'material',
        arrow: true,
        placement: 'bottom-start',
        animation: 'scale-extreme',
        content(reference) {
            const id = reference.getAttribute('data-template');
            const template = document.getElementById(id);
            return template.innerHTML;
        },
        allowHTML: true
    })

    new SwiperSlider()
    new FaqItem()
    new ShowHidden()
    new HeaderMobile()
    new MenuMobile()
    new MenuProduct()
    new HeaderFixed()
    new ButtonTop()

    if(document.getElementById('form--bottom')!= null) {
        createApp(AppFormBottom).use(form).use(Maska).mount('#form--bottom')
    }

    if(document.getElementById('form--sale')!= null) {
        createApp(AppFormSale).use(form).use(Maska).mount('#form--sale')
    }

    if(document.getElementById('form-popup--header')!= null) {
        createApp(AppFormPopupHeader).use(form).use(Maska).mount('#form-popup--header')
    }

    if(document.getElementById('form-popup--consult')!= null) {
        createApp(AppFormPopupConsult).use(form).use(Maska).mount('#form-popup--consult')
    }

    if(document.getElementById('form-popup--send')!= null) {
        createApp(AppFormPopupSend).use(form).use(Maska).mount('#form-popup--send')
    }
})
