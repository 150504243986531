import Swiper, {Autoplay, EffectCoverflow, Navigation, Pagination, Thumbs, Lazy} from 'swiper'
Swiper.use([Navigation, Pagination, EffectCoverflow, Autoplay, Thumbs, Lazy])

export default class SwiperSlider {
    createSliderSlider () {
        return new Swiper('#slider--slider', {
            slidesPerView: 1,
            spaceBetween: 10,
            navigation: {
                nextEl: '.slider--slider-next',
                prevEl: '.slider--slider-prev',
            },
            breakpoints: {
                0: {
                    slidesPerView: 1
                },
                320: {
                    slidesPerView: 1
                },
                360: {
                    slidesPerView: 1
                },
                375: {
                    slidesPerView: 1
                },
                414: {
                    slidesPerView: 1
                },
                480: {
                    slidesPerView: 1
                },
                514: {
                    slidesPerView: 1
                },
                640: {
                    slidesPerView: 1
                },
                768: {
                    slidesPerView: 1
                },
                991: {
                    slidesPerView: 1
                },
                1024: {
                    slidesPerView: 1
                },
                1240: {
                    slidesPerView: 1
                }
            }
        })
    }

    createSliderProducts () {
        return new Swiper('#slider--products', {
            slidesPerView: 4,
            spaceBetween: 15,
            navigation: {
                nextEl: '.slider--products-next',
                prevEl: '.slider--products-prev',
            },
            breakpoints: {
                0: {
                    slidesPerView: 1
                },
                320: {
                    slidesPerView: 1
                },
                360: {
                    slidesPerView: 1
                },
                375: {
                    slidesPerView: 1
                },
                414: {
                    slidesPerView: 2
                },
                480: {
                    slidesPerView: 2
                },
                514: {
                    slidesPerView: 2
                },
                640: {
                    slidesPerView: 2
                },
                768: {
                    slidesPerView: 2
                },
                991: {
                    slidesPerView: 3
                },
                1024: {
                    slidesPerView: 3
                },
                1240: {
                    slidesPerView: 4
                }
            }
        })
    }

    createSliderAdvantages1 () {
        return new Swiper('#slider--advantages1', {
            slidesPerView: 5,
            spaceBetween: 15,
            navigation: {
                nextEl: '.slider--advantages1-next',
                prevEl: '.slider--advantages1-prev',
            },
            breakpoints: {
                0: {
                    slidesPerView: 1
                },
                320: {
                    slidesPerView: 1
                },
                360: {
                    slidesPerView: 1
                },
                375: {
                    slidesPerView: 1
                },
                414: {
                    slidesPerView: 2
                },
                480: {
                    slidesPerView: 2
                },
                514: {
                    slidesPerView: 2
                },
                640: {
                    slidesPerView: 3
                },
                768: {
                    slidesPerView: 3
                },
                991: {
                    slidesPerView: 4
                },
                1024: {
                    slidesPerView: 4
                },
                1240: {
                    slidesPerView: 5
                }
            }
        })
    }

    createSliderReviews () {
        return new Swiper('#slider--reviews', {
            slidesPerView: 2,
            spaceBetween: 15,
            navigation: {
                nextEl: '.slider--reviews-next',
                prevEl: '.slider--reviews-prev',
            },
            autoplay: {
                delay: 5000
            },
            breakpoints: {
                0: {
                    slidesPerView: 1
                },
                320: {
                    slidesPerView: 1
                },
                360: {
                    slidesPerView: 1
                },
                375: {
                    slidesPerView: 1
                },
                414: {
                    slidesPerView: 1
                },
                480: {
                    slidesPerView: 1
                },
                514: {
                    slidesPerView: 1
                },
                640: {
                    slidesPerView: 1
                },
                768: {
                    slidesPerView: 1
                },
                991: {
                    slidesPerView: 1
                },
                1024: {
                    slidesPerView: 2
                },
                1240: {
                    slidesPerView: 2
                }
            }
        })
    }

    createSliderReviewsVideo () {
        return new Swiper('#slider--reviews-video', {
            slidesPerView: 2,
            spaceBetween: 15,
            navigation: {
                nextEl: '.slider--reviews-video-next',
                prevEl: '.slider--reviews-video-prev',
            },
            autoplay: {
                delay: 6000
            },
            breakpoints: {
                0: {
                    slidesPerView: 1
                },
                320: {
                    slidesPerView: 1
                },
                360: {
                    slidesPerView: 1
                },
                375: {
                    slidesPerView: 1
                },
                414: {
                    slidesPerView: 1
                },
                480: {
                    slidesPerView: 1
                },
                514: {
                    slidesPerView: 1
                },
                640: {
                    slidesPerView: 1
                },
                768: {
                    slidesPerView: 1
                },
                991: {
                    slidesPerView: 2
                },
                1024: {
                    slidesPerView: 2
                },
                1240: {
                    slidesPerView: 2
                }
            }
        })
    }

    constructor () {
        if(document.getElementById('slider--slider')!= null) {
            this.createSliderSlider()
        }

        if(document.getElementById('slider--products')!= null) {
            this.createSliderProducts()
        }

        if(document.getElementById('slider--advantages1')!= null) {
            this.createSliderAdvantages1()
        }

        if(document.getElementById('slider--reviews')!= null) {
            this.createSliderReviews()
        }

        if(document.getElementById('slider--reviews-video')!= null) {
            this.createSliderReviewsVideo()
        }
    }
}
