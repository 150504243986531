<template lang="pug">
.form-bottom
  .h2.center(v-html="settings.title")
  .form-bottom__fields
    .form-bottom__field
      .input-text
        input.input-text__input(
          type="tel"
          placeholder="Ваш телефон *"
          v-maska="['+7 (###) ##-##-##', '+7 (###) ###-##-##']"
          v-model="fieldPhone"
          :class="{'input-text__input-error': v$.fieldPhone.$invalid && (fieldPhone.length > 0), 'input-text__input-complete': !v$.fieldPhone.$invalid && (fieldPhone.length > 0)}"
        )
  .form-bottom__bottom
    .form-bottom__consent
      .consent
        input.consent__input(type="checkbox" id="form-checkbox-main" v-model="consent" value="consent" checked="checked")
        label.consent__label(for="form-checkbox-main" v-html="settingConsent.text" @click="getConsent()")
    .form-bottom__button
      .button(:class="{'button-disable': v$.$invalid}" @click="getSend()")
        .button__text(v-html="settings.button")
</template>

<script>
/*global $*/
/*eslint no-undef: "error"*/

import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import { mapActions } from 'vuex'

export default {
  name: 'AppFormBottom',

  setup () {
    return { v$: useVuelidate() }
  },

  data() {
    return {
      settings: {},
      settingConsent: {},
      files: '',
      consent: [],
      consentVal: '',
      fieldPhone: ''
    }
  },

  methods: {
    ...mapActions([
      'Send'
    ]),

    getSend () {
      if (!this.v$.$invalid) {
        $('.button-popup--success').click()
        this.Send({
          setting: {
            number: '1',
            title: 'Заявка с формы',
            subject: 'Заявка с формы "Оставить заявку"'
          },

          fields: [
            {
              title: 'Телефон',
              value: this.fieldPhone
            },
            {
              title: 'Политика',
              value: this.consentVal
            },
            {
              title: 'Id страницы',
              value: this.settings.fields.id
            },
            {
              title: 'Название страницы',
              value: this.settings.fields.pageName
            }
          ],
          files: this.files
        })

        this.fieldPhone = ''

        setTimeout(() => {
          $('.carousel__button').click()
        }, 4000)
      }
    },

    getConsent () {
      this.consentVal = 'проверка'
    }
  },

  validations () {
    return {
      fieldPhone: {
        required,
        minLength: minLength(18),
        maxLength: maxLength(18)
      },

      consent: {
        required
      }
    }
  },

  created () {
    this.settings = window.appGrav.FormPopupBottom
    this.settingConsent = window.appGrav.FormPopupConsent
  }
}
</script>
