export default class MenuProduct {
    classButton = 'tags--button'

    constructor () {
        let self = this

        if(document.querySelector(`.${self.classButton}`) != null) {
            document.querySelectorAll(`.${self.classButton}`).forEach((button) => {
                button.addEventListener('click', function () {
                    let id = '#' + this.getAttribute('data-block')
                    document.querySelector(id).scrollIntoView({
                        behavior: 'smooth',
                        block: 'start'
                    })
                })
            })
        }
    }
}
