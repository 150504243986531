/*global $*/
/*eslint no-undef: "error"*/

export default class HeaderFixed {
    classHeader = 'header'
    classFixed = 'header--fixed'

    fixedBlocks (top, classHeader, classFixed) {
        if (top >= 150) {
            $(`.${classHeader}`).addClass(classFixed)
        } else {
            $(`.${classHeader}`).removeClass(classFixed)
        }
    }

    constructor () {
        let self = this

        self.fixedBlocks($(window).scrollTop(), self.classHeader, self.classFixed)

        $(window).scroll(function () {
            let top = parseInt($(this).scrollTop())
            self.fixedBlocks(top, self.classHeader, self.classFixed)
        })
    }
}
